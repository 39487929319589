import React, { Component } from 'react';
import '../scss/tab-bar.scss'
import HOMEICON_active from '../assets/home.svg'
import HOMEICON from '../assets/home-alt.svg'
import ABOUTICON_active from '../assets/about.svg'
import ABOUTICON from '../assets/about-alt.svg'
import SKILLSICON_active from '../assets/skills.svg'
import SKILLSICON from '../assets/skills-alt.svg'
import CONTACTICON_active from '../assets/contacts.svg'
import CONTACTICON from '../assets/contacts-alt.svg'

class TabBar extends Component{
    // state={
    //     active:'home-tab'
    // }
    componentDidMount() {

        const margin = window.visualViewport.height/2;
        // console.log(margin)
        const sections = []
        sections.push(document.getElementById('home'))
        sections.push(document.getElementById('about'))
        sections.push(document.getElementById('skills'))
        sections.push(document.getElementById('contacts'))
        const ids = ['home-tab','about-tab','skills-tab','contact-tab']
        const tabs = []
        ids.forEach(id=>{
            // console.log(id)
            tabs.push(document.querySelector(`.${id}`))
        })
        // console.log(tabs)

        for(let i =0;i<sections.length;i++){
            let el = sections[i]

            let top = el.getBoundingClientRect().top
            if(Math.abs(top)<margin){
                // console.log({acive:el,top:top,margin:margin});
                if(!tabs[i].classList.contains('active')){
                    tabs.forEach(val=>{
                        val.classList.remove('active')
                        // console.log(val)
                    })
                    tabs[i].classList.add('active')
                }
                break;
            }
        }

        window.addEventListener('scroll',(e)=>{
            // console.log(sections[0].getBoundingClientRect())
            for(let i =0;i<sections.length;i++){
                let el = sections[i]

                let top = el.getBoundingClientRect().top
                if(i===1){

                    let aboutInfo = sections[i].querySelector('.about-illustrations')
                    if(Math.abs(top)<margin/2){
                        if(!aboutInfo.classList.contains('show'))
                            aboutInfo.classList.add('show')
                    }
                }
                if(Math.abs(top)<margin){
                    // console.log({acive:el,top:top,margin:margin});
                    if(!tabs[i].classList.contains('active')){
                        tabs.forEach(val=>{
                            val.classList.remove('active')
                            // console.log(val)
                        })
                        tabs[i].classList.add('active')
                    }
                    break;
                }

            }
        })
    }

    render(){
        return(
            <div id="page-tabs" className="tab-bar d-flex flex-column justify-content-center">
                {/*<a className='tab-logo d-none d-lg-flex' href={'#'}>MBAN</a>*/}
                <div className='tabs d-flex flex-row flex-lg-column '><a href={'#home'} className={'home-tab'} >
                        <img src={HOMEICON} alt={"home"} className={'inactive'}/>
                        <img src={HOMEICON_active} alt={"home-active"} className={'active'}/>
                        <p>HOME</p>
                    </a>
                    <a href={'#about'} className={'about-tab'}>
                        <img src={ABOUTICON} alt={"about"} className={'inactive'}/>
                        <img src={ABOUTICON_active} alt={"about-active"} className={'active'}/>
                        <p>ABOUT</p>
                    </a>
                    <a href={'#skills'} className={'skills-tab'}>
                        <img src={SKILLSICON} alt={"skills"} className={'inactive'}/>
                        <img src={SKILLSICON_active} alt={"skills-active"} className={'active'}/>
                        <p>SKILLS</p>
                    </a>
                    <a href={'#contacts'} className={'contact-tab'}>
                        <img src={CONTACTICON} alt={"contact"} className={'inactive'}/>
                        <img src={CONTACTICON_active} alt={"contact-active"} className={'active'}/>
                        <p>CONTACTS</p>
                    </a>
                </div>
            </div>
        )
    }
}

export default TabBar;