import React,{ Component } from "react";
import '../scss/contents.scss';
import {Laptop_screen} from '../assets/laptop-screen.svg';
import MBANLOGO from '../assets/mban-logo-full.svg'
import sendmail from 'sendmail';
import ALERT_ICON from '../assets/alert-icon.svg';
import GIT_ICON from '../assets/git-icon.svg'
import PHONE_ICON from '../assets/phone-icon.svg'
import LINKEDIN_ICON from '../assets/linkedin-icon.svg'


class Contents extends Component{
    state={
        email:'',
        name:'',
        message:''
    }

    componentDidMount() {
        // const margin = window.visualViewport.height/4;
        // const about = document.getElementById('about')
        // window.addEventListener('scroll',()=>{
        //     let top = about.getBoundingClientRect().top
        //     let aboutInfo = about.querySelector('.about-illustrations')
        //
        //     if(Math.abs(top)<margin){
        //         if(!aboutInfo.classList.contains('show'))
        //             aboutInfo.classList.add('show')
        //     }
        // })
    }

    sendMail=(e)=>{
        let name = this.state.name;
        let email = this.state.email;
        let msg = this.state.message;
        let item
        if(!name||!email||!msg){
            e.preventDefault()
            if(!name) {
                item =document.getElementById('ContactName')
                if(item.classList.contains('alert'))
                    item.classList.remove('alert')
                item.classList.add('alert')
            }
            if(!email){
                item =document.getElementById('ContactEmail')
                if(item.classList.contains('alert'))
                    item.classList.remove('alert')
                item.classList.add('alert')
            }
            if(!msg){
                item =document.getElementById('ContactMessage')
                if(item.classList.contains('alert'))
                    item.classList.remove('alert')
                item.classList.add('alert')
            }

            // alert("All fields are required")
        }
        else{
            // sendmail({silent:true,devPort:3000})({
            //     from: this.state.email,
            //     to: 'amanuel@mbandevelopers.com',
            //     subject: 'Website Contact',
            //     html: `Contact Name:${this.state.name}\nMessage:${this.state.message}`,
            // }, function(err, reply) {
            //     console.log(err && err.stack);
            //     console.dir(reply);
            // });
        }

    }
    updateValue = (e,type)=>{
        let item
        switch(type){
            case 'name':
                this.setState({name:e.target.value})
                item =document.getElementById('ContactName')
                if(item.classList.contains('alert'))
                    item.classList.remove('alert')
                break;
            case 'email':
                this.setState({email:e.target.value})
                item =document.getElementById('ContactEmail')
                if(item.classList.contains('alert'))
                    item.classList.remove('alert')
                break;
            case 'msg':
                this.setState({message:e.target.value})
                item =document.getElementById('ContactMessage')
                if(item.classList.contains('alert'))
                    item.classList.remove('alert')
                break;
            default:
                break;
        }
    }

    render(){
        return(<div className='contents-container' data-spy="scroll" data-target="#page-tabs" data-offset={"0"}>
            <section id={'home'} className={'col-12 col-lg-11 d-flex flex-column flex-lg-row'}>
                <div className={'home-text col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start'}>
                    <div className={'col-12 p-0 d-flex justify-content-start'}><img src={MBANLOGO}/></div>
                    <div>
                        <p>MBAN is a group of young software developers based in Addis Ababa and we know we can give you the service you need.</p>
                    </div>
                    <div className={'col-12 d-flex justify-content-start '}><a href={'#contacts'} className={''} >Contact Us!</a></div>
                </div>
                <div className={'home-illustrations col-12 col-lg-6 d-flex justify-content-center align-items-center'}>
                    <div className={'ill'}>{Laptop()}</div>
                </div>
            </section>
            <section id={'about'} className={'col-12 col-lg-11 d-flex flex-column flex-lg-row'}>
                <div className={'about-text col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start'}>
                    <h1>WHO ARE WE?</h1>
                    <div>
                        <p>We are computer science undergrads that are trying to make a name for our selves. We are here to give you what you need and we know we can satisfy.</p>
                    </div>
                </div>
                <div className={'about-illustrations col-12 col-lg-6 d-flex flex-row justify-content-around align-items-center'}>
                    <div className={'left'}>
                        <a className={'profile'} href={'https://github.com/Mussiefekadu'} target={'_blank'}>
                            <div className={'profile-img'}>
                                <img src={'https://avatars.githubusercontent.com/u/45538827?v=4'}/>
                            </div>
                            <div className={'profile-info'}>
                                <h3>Mussie</h3>
                                <p>Front-End</p>
                            </div>
                        </a>
                        <a className={'profile'} href={'https://github.com/Aman-Yanni'} target={'_blank'}>
                            <div className={'profile-img'}>
                                <img src={'https://avatars.githubusercontent.com/u/71527685?v=4'}/>
                            </div>
                            <div className={'profile-info'}>
                                <h3>Amanuel</h3>
                                <p>Front-End</p>
                            </div>
                        </a>
                    </div>
                    <div className={'right'}>
                        <a className={'profile'} href={'https://github.com/brukberhane'}  target={'_blank'}>
                            <div className={'profile-img'}>
                                <img src={'https://avatars.githubusercontent.com/u/20716815?v=4'}/>
                            </div>
                            <div className={'profile-info'}>
                                <h3>Bruk</h3>
                                <p>Back-End</p>
                            </div>
                        </a>
                        <a className={'profile'} href={'https://github.com/PethioPan'} target={'_blank'}>
                            <div className={'profile-img'}>
                                <img src={'https://avatars.githubusercontent.com/u/31758523?v=4'}/>
                            </div>
                            <div className={'profile-info'}>
                                <h3>Nahom</h3>
                                <p>Front-End</p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section id={'skills'} className={'col-12 col-lg-11 d-flex flex-column flex-lg-row'}>
                <div className={'skills-text col-12 col-lg-6 d-flex order-2 order-lg-1 flex-column justify-content-center align-items-start'}>
                    <h1>WHAT WE DO?</h1>
                    <div>
                        <p>We do Front-End, Back-End and anything in between.</p>
                    </div>
                </div>
                <div className={'skills-illustrations col-12 col-lg-6 d-flex order-1 order-lg-2 flex-row justify-content-around align-items-center'}>

                </div>
            </section>
            <section id={'contacts'} className={'col-12 col-lg-11 d-flex flex-column flex-lg-row'}>
                <div className={'contacts-form col-12 col-lg-6 d-flex order-2 order-lg-1 flex-column justify-content-center align-items-start'}>
                    <h1>Contact Us</h1>
                    <form className={'d-flex flex-column col-9'} action='mailto:support@mbandevelopers.com?subject=Contact Mail From Website'
                        method='POST'
                        enctype='multipart/form-data'
                        name='EmailForm'
                        id={'EmailForm'}>
                        <div>
                            <input placeholder={'Name'} type={'text'} id={"ContactName"} name={"ContactName"}value={this.state.name} onChange={(e)=>this.updateValue(e,'name')}/>
                            <span className={'name'}><img src={ALERT_ICON}/></span>
                        </div>
                        <div>
                            <input placeholder={'Email'} type={'email'} id={"ContactEmail"} name={"ContactEmail"} value={this.state.email} onChange={(e)=>this.updateValue(e,'email')}/>
                            <span className={'email'}><img src={ALERT_ICON}/></span>
                        </div>
                        <div>
                            <textarea placeholder={'Message'} id={'ContactMessage'} name={'ContactMessage'} value={this.state.message} onChange={(e)=>this.updateValue(e,'msg')}></textarea>
                            <span className={'msg'}><img src={ALERT_ICON}/></span>
                        </div>
                        <button
                            onClick={(e)=>this.sendMail(e)}
                            type={'submit'}
                        >Send Message</button>
                    </form>
                </div>
                <div className={'contacts-links col-12 col-lg-6 d-flex order-1 order-lg-2 flex-column justify-content-center align-items-center'}>
                    <div className={'col-12'}>
                        <h2>Where to find us.</h2>
                        <div className={'inks-container d-inline-flex m-auto flex-column justify-content-center align-items-start'}>
                            <a  href={'https://github.com/MBAN-Devs'} target={'_blank'}>
                                <div><img src={GIT_ICON}/></div>
                                <p>Github</p>
                            </a>
                            <a href={'https://www.linkedin.com/company/mban'} target={'_blank'}>
                                <div><img src={LINKEDIN_ICON}/></div>
                                <p>LinkedIn</p>
                            </a>
                            <span>
                            <div><img src={PHONE_ICON}/></div>
                            <p>+251915573311</p>
                        </span>

                        </div>
                    </div>
                </div>
            </section>
        </div>)
    }
}

const Laptop = () =>{
    return(<svg width="597" height="510" viewBox="0 0 597 510" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="laptop">
                <g id="laptop-object">
                    <g id="Vector 26" filter="url(#filter0_d)">
                        <path d="M84.8249 69.4265L440.048 41.9597C453.264 40.9379 464.805 50.8227 465.827 64.0381L481.819 270.86L483.176 288.406L484.175 301.328C484.438 304.726 485.421 308.029 487.059 311.017L522.124 374.964C530.505 390.249 520.31 409.088 502.93 410.432L84.4252 442.792C67.6667 444.087 54.8018 428.2 59.5542 412.077L80.2448 341.885C81.07 339.085 81.3777 336.159 81.1527 333.249L80.0952 319.573L78.7386 302.028L62.7465 95.2053C61.7247 81.9899 71.6095 70.4484 84.8249 69.4265Z" fill="#252525"/>
                        <path d="M483.176 288.406L481.819 270.86L465.827 64.0381C464.805 50.8227 453.264 40.9379 440.048 41.9597L84.8249 69.4265C71.6095 70.4484 61.7247 81.9899 62.7465 95.2053L78.7386 302.028L80.0952 319.573M483.176 288.406L484.175 301.328C484.438 304.726 485.421 308.029 487.059 311.017L522.124 374.964C530.505 390.249 520.31 409.088 502.93 410.432L84.4252 442.792C67.6667 444.087 54.8018 428.2 59.5542 412.077L80.2448 341.885C81.07 339.085 81.3777 336.159 81.1527 333.249L80.0952 319.573M483.176 288.406L80.0952 319.573" stroke="black"/>
                    </g>
                    <path id="Vector 27" d="M461.697 265.338L97.8999 293.468L82.0566 88.5702L445.854 60.4404L461.697 265.338Z" fill="#424141" stroke="url(#paint0_radial)"/>
                    <path id="Vector 28" d="M202.332 420.003L211.454 386.697L331.958 377.379L344.506 409.01L202.332 420.003Z" fill="#424040"/>
                    <path id="Vector 29" d="M88.4481 388.847L111.319 335.548L458.484 308.704L489.276 357.854L88.4481 388.847Z" fill="#424040"/>
                </g>
                <g id="laptop-icon">
                    <path id="Vector" d="M270.429 212.338L306.272 209.566C306.817 209.524 307.364 209.627 307.856 209.865C308.349 210.104 308.769 210.469 309.074 210.923L314.731 219.5C315.034 219.945 315.213 220.463 315.252 221.001C315.29 221.538 315.186 222.076 314.95 222.561C314.713 223.045 314.354 223.459 313.907 223.759C313.46 224.06 312.941 224.238 312.403 224.274L267.153 227.773C266.63 227.813 266.105 227.719 265.628 227.499C265.151 227.28 264.738 226.942 264.428 226.519C264.118 226.096 263.921 225.6 263.856 225.079C263.79 224.559 263.859 224.03 264.054 223.543L267.707 214.271C267.93 213.724 268.305 213.251 268.787 212.908C269.269 212.566 269.839 212.368 270.429 212.338Z" fill="#0FFCC3"/>
                    <path id="Vector_2" d="M314.215 180.536L290.357 127.746C290.039 127.049 289.486 126.486 288.794 126.157C288.103 125.827 287.317 125.751 286.576 125.943C285.834 126.134 285.183 126.58 284.738 127.203C284.292 127.826 284.08 128.586 284.139 129.35L286.78 163.505C286.933 164.984 287.491 166.393 288.393 167.575C288.868 168.174 289.158 168.898 289.228 169.658C289.226 170.66 288.847 171.625 288.165 172.359C287.484 173.094 286.551 173.545 285.552 173.622C284.553 173.699 283.561 173.397 282.775 172.776C281.989 172.155 281.466 171.26 281.31 170.27C281.253 169.564 281.388 168.854 281.701 168.218C282.398 166.906 282.732 165.432 282.669 163.948L280.026 129.768C279.983 128.99 279.663 128.254 279.124 127.692C278.585 127.13 277.862 126.779 277.087 126.703C276.312 126.627 275.535 126.831 274.897 127.278C274.26 127.725 273.803 128.386 273.61 129.141L258.557 183.441C258.222 184.655 258.144 185.924 258.326 187.17C258.509 188.415 258.948 189.609 259.615 190.676L266.983 202.316C267.866 203.706 269.111 204.829 270.585 205.564C272.058 206.299 273.704 206.619 275.346 206.489L300.838 204.518C302.374 204.4 303.855 203.892 305.14 203.041C306.425 202.19 307.47 201.025 308.178 199.656L314.024 188.391C314.647 187.181 314.988 185.846 315.021 184.486C315.054 183.125 314.779 181.775 314.215 180.536Z" fill="#0FFCC3"/>
                    <path id="Vector_3" d="M149.282 181.428L206.19 151.733C207.116 151.248 208.148 151.001 209.193 151.013C210.239 151.025 211.265 151.296 212.179 151.802C213.094 152.308 213.87 153.032 214.436 153.911C215.002 154.79 215.342 155.795 215.424 156.837C215.524 158.083 215.252 159.33 214.643 160.422C214.034 161.513 213.115 162.4 212.003 162.969L163.729 187.752C163.235 187.948 162.817 188.297 162.536 188.748C162.255 189.199 162.125 189.728 162.166 190.258C162.207 190.787 162.417 191.29 162.764 191.692C163.111 192.094 163.578 192.375 164.096 192.493L215.571 209.438C216.759 209.831 217.804 210.566 218.578 211.549C219.351 212.533 219.817 213.723 219.919 214.97C220.004 216.02 219.826 217.075 219.401 218.039C218.977 219.003 218.319 219.847 217.487 220.493C216.655 221.14 215.675 221.569 214.636 221.742C213.596 221.916 212.53 221.828 211.534 221.486L150.786 200.888C148.816 200.223 147.085 198.993 145.808 197.351C144.531 195.71 143.765 193.729 143.605 191.655C143.451 189.583 143.906 187.51 144.915 185.693C145.923 183.876 147.441 182.393 149.282 181.428Z" fill="#0FFCC3"/>
                    <path id="Vector_4" d="M228.177 226.816L253.192 126C253.434 124.954 254.007 124.015 254.827 123.321C255.646 122.628 256.668 122.218 257.739 122.152L259.427 122.022C260.323 121.943 261.226 122.083 262.056 122.429C262.887 122.775 263.622 123.316 264.198 124.008C264.774 124.699 265.174 125.52 265.364 126.4C265.554 127.28 265.529 128.192 265.29 129.06L239.932 228.679C239.64 229.813 239.003 230.827 238.108 231.581C237.213 232.335 236.105 232.791 234.938 232.886L233.499 232.997C232.702 233.077 231.899 232.964 231.155 232.667C230.412 232.37 229.751 231.898 229.229 231.292C228.707 230.685 228.339 229.962 228.155 229.183C227.972 228.404 227.979 227.592 228.177 226.816Z" fill="#0FFCC3"/>
                    <path id="Vector_5" d="M391.236 182.296L334.327 211.992C333.397 212.483 332.358 212.735 331.305 212.724C330.252 212.713 329.219 212.439 328.299 211.927C327.379 211.415 326.602 210.682 326.037 209.794C325.472 208.905 325.138 207.89 325.065 206.84C324.975 205.595 325.255 204.352 325.868 203.265C326.481 202.179 327.401 201.297 328.513 200.73L376.548 176.091C377.042 175.895 377.46 175.546 377.741 175.095C378.022 174.644 378.152 174.115 378.111 173.585C378.07 173.056 377.86 172.553 377.513 172.151C377.166 171.748 376.699 171.468 376.181 171.35L324.891 154.216C323.708 153.825 322.667 153.094 321.898 152.114C321.129 151.134 320.667 149.949 320.57 148.707C320.489 147.662 320.67 146.612 321.095 145.654C321.521 144.696 322.178 143.859 323.007 143.217C323.837 142.576 324.813 142.151 325.848 141.981C326.882 141.811 327.943 141.901 328.934 142.242L389.731 162.836C391.702 163.501 393.433 164.731 394.71 166.373C395.986 168.015 396.752 169.995 396.912 172.069C397.07 174.142 396.616 176.216 395.607 178.034C394.598 179.851 393.078 181.333 391.236 182.296Z" fill="#0FFCC3"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_d" x="0.241211" y="0.92691" width="596.182" height="508.254" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="19" dy="14"/>
                    <feGaussianBlur stdDeviation="25"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0117361 0 0 0 0 0.108333 0 0 0 0 0.0979689 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.877 176.954) rotate(85.5785) scale(102.755 182.442)">
                    <stop/>
                    <stop offset="1"/>
                </radialGradient>
            </defs>
        </svg>
    )
}

export default Contents;