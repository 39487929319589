import TabBar from './components/TabBar';
import Contents from './components/Contents';
import React,{ Component } from 'react';
import './scss/App.scss'
import particlesJS from "./particles/particles";
import particleconfig from './particles/particle-config.json'

class App extends Component {

    componentDidMount() {
        particlesJS('main-bg',particleconfig)
    }

    render(){

    return (
      <div className="App">
        <div className='main-bg' id='main-bg'>
        </div>
        <TabBar/>
        <Contents/>
      </div>
    );
  }
}



export default App;
